@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

@layer components{
  .btn-primary{
    @apply text-[16px] lg:text-[18px] border hover:bg-white hover:text-black
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181818;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fbc{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title{
  font-size: 95px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 105px;
}

.fs20{
  font-size: 20px;
}

.fw500{
  font-weight: 500;
}
.fw700{
  font-weight: 700;
}
.fw800{
  font-weight: 800;
}

@media screen and (max-width: 500px) {
  .title{
    font-size: 36px;
    line-height: 40px;
  }
}

@media screen and (min-width : 600px) and (max-width: 1024px) {
  .title{
    font-size: 59px;
    line-height: 65px;
  }
}
